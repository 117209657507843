<template>
  <section>
    <div class="ll-topbox">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ $t('Account Center') }}
        </b-breadcrumb-item>
        <!-- <b-breadcrumb-item active >
          {{$route.params.userId}}
        </b-breadcrumb-item> -->
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'user-list' }"
      v-if="false"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    </div>

    <!-- content -->
    <b-row>
      <b-col
        cols="12"
      >
        <b-card no-body>
          <b-card-body>
            <b-tabs
              pills
              class="ll-tabs"
            >
              <!-- Tab: Profile -->
              <b-tab active>
                <template #title>
                  <span class="d-none d-sm-inline">{{ $t('Profile') }}</span>
                </template>
                <user-profile :uId="uId" />
              </b-tab>
              <!-- Tab: Password -->
              <b-tab>
                <template #title>
                  <span class="d-none d-sm-inline">{{ $t('Password') }}</span>
                </template>
                <user-password :uId="uId" />
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BAvatar, BButton, BFormGroup, BFormInput, BCardHeader, BCardTitle,
  BCard, BFormFile, BBreadcrumb, BBreadcrumbItem,
  BCardBody, BBadge, BDropdown, BDropdownItem, BImg, BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import userProfile from './userProfile2.vue'
import userPassword from './userPassword2.vue'

export default {
  components: {
    userProfile,
    userPassword,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BBadge,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    flatPickr,
    BImg,
    BTab,
    BTabs,
  },
  data() {
    return {
      userInfo: {
        company: '',
        coverImg: '',
        createdAt: '',
        createdBy: '',
        createdName: '',
        departmentName: '',
        email: '',
        fullName: '',
        fullNameLocale: '',
        isEnabled: '',
        isLocked: '',
        lastLoginAt: '',
        mfaMethod: '',
        phone: '',
        phoneAreaCode: '',
        sex: '',
        updatedAt: '',
        updatedBy: '',
        updatedName: '',
        userExpireAt: '',
        userName: '',
      },
      avata: [],
      roleList: [],
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 1,
      organizationId: '',
      companyList: [],
      condition: {
        page: '1',
        size: '15',
        startTime: '',
        endTime: '',
        roleName: '',
        status: '',
        orderMole: 0,
        orderBy: 'userId',
        organizationId: '',
        userName: '',
      },
      userList: [],
      uId: null,
    }
  },
  created() {
    this.uId = this.$route.params.userId
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      this.$http.post('/user/search', this.condition).then(res => {
        // console.log('ss', res.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.userList = info.content
        }
      }).catch(error => console.log(error))
    },
    submit() {
    },
    onUser(userId) {
      // console.log('sss', userId)
      this.uId = userId.toString()
    },
  },
}
</script>
<style scoped>
.ll-rbtn{
  margin-bottom: 10px;
  text-align: center;
}
.ll-rbtn button{
  width: 135px;
}
.ll-cbody{
  min-height: 215px;
}
.ll-flex{
  justify-content: space-around;
  width: 30%;
}
.justify-content-start{
  margin-bottom: 10px;
}
.ll-tag, .ll-dropdown{
  width: 100%;
  text-align: right;
  margin-top: 15px;
}
.ll-dropdown .dropdown{
  width: 160px;
}
.ll-dropdown .ll-btn{
  width: 130px;
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-topbox{
  display: flex;
  justify-content: space-between;
}
.ll-userList{
  cursor: pointer;
}
.ll-userName:hover{
  color: #ed6d01;
}
</style>
<style>
[dir] .custom-file {
  height: 34px;
}
/* [dir] .breadcrumb-top {
  margin-bottom: unset;
} */
</style>
