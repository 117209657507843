<template>
  <section>
    <validation-observer ref="password">
      <b-row>
        <b-col md='4'>
          <b-form-group
            class="ll-bform"
            label="Enter a new password"
            label-for="new_password"
          >
            <validation-provider
              #default="{ errors }"
              name="new_password"
              :rules="{required:true,regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\\$%\\^\\&*+=_])[A-Za-z\d!@#\\$%\\^\\&*+=_]{12,20}$/}"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
              <b-form-input
                id="new_password"
                v-model="newPwd1"
                :type="passwordFieldType"
                class="form-control-merge"
                @blur="validatePassword"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md='4'>
          <b-form-group
            class="ll-bform"
            label="Re-enter a new password"
            label-for="new_Repassword"
          >
            <validation-provider
              #default="{ errors }"
              name="new_Repassword"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
              <b-form-input
                id="new_Repassword"
                v-model="newPwd2"
                :type="passwordFieldType1"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon1"
                  @click="togglePasswordVisibility1"
                />
              </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        Submit
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BRow, BCol, BButton, BFormInput, BFormGroup, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility, togglePasswordVisibility1 } from '@core/mixins/ui/forms'

export default {
  components:{
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    uId: String,
  },
  mixins: [togglePasswordVisibility, togglePasswordVisibility1],
  data() {
    return {
      required,
      newPwd1: '',
      newPwd2: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIcon1() {
      return this.passwordFieldType1 === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    uId(val) {
      this.uId = val
      // this.getUserDetail()
    }
  },
  created() {},
  mounted() {
  },
  methods: {
    resetPwd() {
      const data = {}
      data.id = this.uId
      data.password = this.newPwd1
      this.$http.post('user/resetPassword', data).then(res =>{
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'update password success, please login again',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'auth-login' })
        }
      })
    },
    validatePassword() {
      // password requirement: at least 12 characters, contains at least one uppercase letter, one lowercase letter, one number, and one special character
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#\$%\^\&*\)\(+=\/\?<>,.:;_\-|'"\[\{\]\}\\])[A-Za-z\d`~!@#\$%\^\&*\)\(+=\/\?<>,.:;_\-|'"\[\{\]\}\\]{12,20}$/
      if (this.newPwd1.length > 0 && !passwordRegex.test(this.newPwd1)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The password must be at least 12 characters, contains at least one uppercase letter, one lowercase letter, one number, and one special character',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    validationForm() {
      this.$refs.password.validate().then(success => {
        if (success) {
          if (this.newPwd1.length > 5) {
            if (this.newPwd2 === this.newPwd1) {
              this.resetPwd()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'new password inconsistency',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'The password must be no less than 6 characters',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>
<style>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
</style>
